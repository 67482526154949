<template>
    <div
        :style="bgColorStyle"
        :class="sizeClass"
        class="inline-flex items-center justify-center rounded-full text-white font-medium tracking-wider"
    >
        {{ initials }}
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    firstname: {
        type: String,
        required: true,
    },
    lastname: {
        type: String,
        required: true,
    },
    size: {
        type: String,
        default: 'md',
        validator: (value) => ['xs', 'sm', 'md', 'lg', 'custom'].includes(value),
    },
});

const initials = computed(() => {
    return `${props.firstname.charAt(0)}${props.lastname.charAt(0)}`.toUpperCase();
});

const generateHash = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
};

const bgColorStyle = computed(() => {
    const combinedName = props.firstname + props.lastname;
    const hash = generateHash(combinedName);
    const hue = hash % 360;

    const color1 = `hsl(${hue}, 50%, 40%)`;
    const color2 = `hsl(${hue}, 70%, 50%)`;

    return {
        background: `linear-gradient(45deg, ${color1}, ${color2})`,
    };
});

const sizeClass = computed(() => {
    switch (props.size) {
        case 'xs':
            return 'size-8 text-xs';
        case 'sm':
            return 'size-10 text-xs';
        case 'md':
            return 'size-16 text-xl';
        case 'lg':
            return 'size-20 text-2xl';
        case 'custom':
            return '';

        default:
            return 'size-16 text-xl';
    }
});
</script>
